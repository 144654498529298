import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import "./Carousel.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.message
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields.",
      });
      return;
    }

    emailjs
      .sendForm(
        "service_n65v87k",
        "template_pzrbylg",
        e.target,
        "HMVa-ZAtowuTq8vN9"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your message has been sent.",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong! Please try again later.",
          });
        }
      );

    e.target.reset();
    setFormData({ name: "", email: "", phone: "", message: "" });
  };

  return (
    <div className="mt-4 mb-4 form-box">
      <h3
        className="text-center"
        style={{ fontFamily: "Roboto, sans-serif", color: "#dc3545" }}
      >
        Your Child's Journey with Little Elly
      </h3>
      <h6 className="text-center" style={{ fontFamily: "Roboto, sans-serif" }}>
        Admissions open for 2024-2025
      </h6>
      <form onSubmit={sendEmail} className="">
        <div className="form-group">
          <label htmlFor="name" className="mt-4">
          </label>
          <input
            className="form-control"
            id="name"
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="mt-4">
          </label>
          <input
            className="form-control"
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone" className="mt-4">
          </label>
          <input
            className="form-control"
            id="phone"
            type="phone"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message" className="mt-4">
          </label>
          <textarea
            className="form-control"
            id="message"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <div className="text-center">
          <input
            className="mt-4 btn text-white"
            style={{ backgroundColor: "#dc3545" }}
            type="submit"
            value="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
