import React, { useRef } from "react";
import SwiperCore from "swiper/core";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Grid, Button, Container } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Video1 from "../videos/v1.mp4";
import Video2 from "../videos/v3.mp4";
import Video3 from "../videos/v4.mp4";
import Video4 from "../videos/v5.mp4";

SwiperCore.use([Navigation]);

const VideoCarousel = [
  {
    id: 1,
    video: Video1,
  },
  {
    id: 2,
    video: Video2,
  },
  {
    id: 3,
    video: Video3,
  },
  {
    id: 4,
    video: Video4,
  },
  
];

const KitchenCarousel = () => {
  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <Container className="my-5">
      <Swiper
        ref={swiperRef}
        spaceBetween={5}
        slidesPerView={3}
        navigation={{
          nextEl: ".swiper-button-next-product",
          prevEl: ".swiper-button-prev-product",
        }}
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        {VideoCarousel.map((videoObject) => (
          <SwiperSlide align="center" key={videoObject.id}>
            <Grid item xs={12} md={8}>
              <video
                controls
                src={videoObject.video}
                style={{
                  height: "300px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </Grid>
          </SwiperSlide>
        ))}
        <Grid container spacing={2} className="mt-3">
          <Grid item xs={6} className="text-start">
            <Button
              onClick={goPrev}
              className="swiper-button-prev-product"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6} className="text-end">
            <Button
              onClick={goNext}
              className="swiper-button-next-product"
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Swiper>
    </Container>
  );
};

export default KitchenCarousel;
