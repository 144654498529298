import Carousel from 'react-bootstrap/Carousel';
import React from 'react';
import Image2 from '../images/banner-8.png';
import Image3 from '../images/banner3.png';
import Image5 from '../images/banner5.png';
import Image6 from '../images/banner-7.png';
import '../pages/Carousel.css'; 

function Homecarousel() {
  const staticItems = [
     {
      bannerImage: Image2,
      bannertitle: 'Banner 2',
    },
    {
      bannerImage: Image3,
      bannertitle: 'Banner 3',
    },
    
    {
      bannerImage: Image5,
      bannertitle: 'Banner 5',
    },
    {
      bannerImage: Image6,
      bannertitle: 'Banner 6',
    },
    
  ];

  return (
    <Carousel fade className='mt-'>
      {staticItems.map((res, index) => (
        <Carousel.Item key={index}>
          <img
            src={res.bannerImage}
            alt={res.bannertitle}
            width="100%"
            height=""
            className="d-block w-100" 
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Homecarousel;
