import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Mainpage from "./components/Mainpage";
import Whatsapp from "./pages/callbutton";
import Gallery from "./components/Gallery";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Mainpage />} />
          <Route path="/gallery" element={<Gallery />} /> 
        </Routes>
        <Whatsapp />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
