import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Img1 from "../images/test1.jpg";
import Img2 from "../images/test2.jpg";
import Img3 from "../images/test3.jpg";
import "../pages/Testimonials.css";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
        renderArrowPrev={() => <div />}
        renderArrowNext={() => <div />}
      >
        <div>
          <img src={Img1} alt="" />
          <div className="myCarousel">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3 text-center mt-4">
              <h4 className="text-success serh4">Shirley Fultz</h4>
            </div>

            <h4>Parent</h4>
            <p>
              I'm impressed by the personalized attention and holistic
              development approach at Little Elly. It's a place where my child
              thrives happily while learning and exploring.
            </p>
          </div>
        </div>

        <div>
          <img src={Img2} alt="" />
          <div className="myCarousel">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3 text-center mt-4">
              <h4 className="text-success serh4">Daniel Keystone</h4>
            </div>

            <h4>Parent</h4>
            <p>
              Choosing Little Elly for my child was the best decision. The
              engaging curriculum and caring staff have made learning a joyful
              journey for my little learner.
            </p>
          </div>
        </div>

        <div>
          <img src={Img3} alt="" />
          <div className="myCarousel">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3 text-center mt-4">
              <h4 className="text-success serh4">Theo Sorel</h4>
            </div>

            <h4>Parent</h4>
            <p>
              Little Elly has been an amazing experience for my child! The
              nurturing environment and dedicated teachers have helped my little
              one grow both academically and socially.
            </p>
          </div>
        </div>
      </Carousel>
    );
  }
}
