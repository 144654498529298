import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Homecarousel from "../pages/Homecarousel";
import Videocarousel from "../pages/Videocarousel";
import ContactForm from "../pages/contact";
import Testimonials from "../pages/Testimonials";
// import InquiryForm from "../components/InquiryForm ";
import home1 from "../images/home1.jpg";
import home2 from "../images/home2.png";
import home3 from "../images/home3.jpeg";
import fam1 from "../images/fam1.jpg";
import fam2 from "../images/fam2.jpg";
import fam3 from "../images/fam3.jpg";
import ach1 from "../images/achive1.jpeg";
import ach2 from "../images/achive2.jpeg";
import ach3 from "../images/achive3.jpeg";
import Img1 from "../images/little21.jpg";
import Img2 from "../images/little1.jpg";
import Img3 from "../images/little2.jpg";
import Img4 from "../images/little3.jpg";
import Img5 from "../images/little4.jpg";
import Img6 from "../images/little9.jpg";
import Img7 from "../images/little13.jpg";
import Img8 from "../images/little18.jpg";
import Img9 from "../images/little20.jpg";
import Img10 from "../images/little21.jpg";
import Img11 from "../images/little22.jpg";

const Mainpage = () => {
  const bannerStyle = {
    display: "flex",
    position: "relative",
  };

  const contentStyle = {
    position: "absolute",
    top: "50%",
    left: "10%",
    transform: "translateY(-50%)",
    color: "white",
    textDecoration: "none",
  };

  const imgStyle = {
    width: "100%",
    height: "auto",
  };
  const imageStyle = {
    maxWidth: "80%",
    height: "auto",
  };

  const galleryContainerStyle = {
    padding: "20px",
  };

  const galleryItemStyle = {
    marginBottom: "20px",
  };

  const viewButtonStyle = {
    backgroundColor: "#dc3545",
  };
  return (
    <div>
      {/* <InquiryForm /> */}
      <Homecarousel className="mt-" />
      {/* ABOUT */}
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="p-3 mb-2 bg-white rounded mt-4">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <img
                    src={home1}
                    className="img-fluid rounded-3"
                    alt="about"
                  />
                </div>
                <div className="col-lg-8 mb-3">
                  <h5 className="mb-2 colorhead">Ananth Nagar</h5>
                  <p className="serpara" style={{ textAlign: "justify" }}>
                    Located at the heart of Electronic City in Bengaluru, our
                    Little Elly center at 203, Saptagiri, 4th Main, 15th Cross
                    Rd, Ananth Nagar, Phase 1, Kammasandra, is a vibrant hub
                    where young minds embark on a journey of exploration and
                    discovery. Nestled within this bustling community, our
                    school provides a nurturing and safe environment for
                    children to flourish, fostering a love for learning in every
                    corner of this dynamic neighborhood. Surrounded by verdant
                    landscapes and modern facilities, our center encourages
                    creativity and curiosity, offering an immersive educational
                    experience that inspires growth and development in our
                    students.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bg-light">
          <div className="row">
            <div className="col-lg-6">
              <div className="p-3 mb-2  rounded">
                <h5 className="mb-4 colorhead">Little Eilly </h5>
                <p
                  className="serpara mt-4 mb-4"
                  style={{ textAlign: "justify" }}
                >
                  Little Elly is a nurturing haven where imagination blooms and
                  learning takes flight. With vibrant activities and a caring
                  environment, it's a place where every child's curiosity is
                  sparked and encouraged. Through engaging play-based learning,
                  Little Elly fosters a sense of wonder, creativity, and social
                  development, laying a strong foundation for lifelong learning.
                  Its dedicated educators and safe, inviting spaces create a
                  delightful haven for children to explore, discover, and grow.
                  Little Elly is where joy-filled moments and educational
                  milestones intersect, shaping bright futures one playful day
                  at a time.Spacious Classrooms: Well-lit and ventilated
                  classrooms provide a comfortable learning environment. Play
                  Area: A safe and well-equipped play area encourages physical
                  activity and social interaction. Library: Our library promotes
                  a love for reading and supports language development.
                  Technology Integration: We leverage age-appropriate technology
                  to enhance learning experiences.
                </p>
                <div className="col-lg-6 mb-3">
                  <img src={Img1} className="img-fluid rounded-3" alt="about" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" mb-2  rounded">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="events">
        <div className="row m-lg-5 mx-sm-4 py-5">
          <Container>
            <Row className="m-0">
              <Col lg={6} sm={12} className="p-3">
                <iframe
                  title="YouTube video player"
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/6YQKb6-8sOI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Col>
              <Col className="p-3 d-flex flex-column align-items-baseline justify-content-center">
                <h2 className="text-danger mb-2">Our Vision</h2>
                <h4 className="title-2">Inspiring Young Minds</h4>
                <h4 className="sub-title">Unlocking Creativity</h4>
                <p>
                  At the core of our philosophy is the belief that every child
                  is a natural learner. We aim to nurture their curiosity and
                  creativity, guiding them to explore, create, and innovate.
                </p>
                <p>
                  Our approach empowers children to learn through exploration
                  and play, fostering a sense of wonder and curiosity that
                  ignites a lifelong passion for learning.
                </p>
                <Button
                  variant="danger"
                  size="md"
                  className="btn-danger-gradient"
                  href="#about"
                  tabIndex="0"
                >
                  Explore More
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <div className="container-fluid bg-light">
          <div className="row bg-light-img m-lg-5 py-5 mt-0">
            <div className="col text-center">
              <h4
                className="text-secondary mb-2"
                style={{
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                The Joy of Learning
              </h4>
              <h2
                className="title-1"
                style={{
                  fontFamily: "Roboto, sans-serif",
                  color: "#dc3545",
                }}
              >
                Wonder what a day of joy looks like at Little Elly?
              </h2>
              <h2
                className="sub-title"
                style={{
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                Ta-da!
              </h2>
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img src={Img6} alt="Nurture" className="card-img-top" />
                      <div className="card-body">
                        <h5
                          className="card-title joy-title"
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          The Joy of Nurture
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img
                        src={Img7}
                        alt="Curiosity"
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h5
                          className="card-title joy-title"
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          The Joy of Curiosity
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img src={Img8} alt="Wonder" className="card-img-top" />
                      <div className="card-body">
                        <h5
                          className="card-title joy-title"
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          The Joy of Wonder
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img
                        src={Img9}
                        alt="Adventure"
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h5
                          className="card-title joy-title"
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          The Joy of Adventure
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img
                        src={Img10}
                        alt="Exploration"
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h5
                          className="card-title joy-title"
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          The Joy of Exploration
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img
                        src={Img11}
                        alt="Imagination"
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h5
                          className="card-title joy-title"
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          The Joy of Imagination
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section  style={bannerStyle}>
        <div style={contentStyle}></div>
        <img src={home3} style={imgStyle} alt="" />
      </section>
      <section id="gallery">
        <div className="container-fluid bg-light">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-12 text-center mt-4">
              <h4 className="text-danger serh4">Our Childrens Gallery</h4>
            </div>
          </div>
          <div
            className="row justify-content-center"
            style={galleryContainerStyle}
          >
            <div
              className="col-lg-3 col-md-6 col-sm-12 mb-3"
              style={galleryItemStyle}
            >
              <img
                src={Img2}
                className="img-fluid rounded-3"
                alt="about"
                style={imageStyle}
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 mb-3"
              style={galleryItemStyle}
            >
              <img
                src={Img3}
                className="img-fluid rounded-3"
                alt="about"
                style={imageStyle}
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 mb-3"
              style={galleryItemStyle}
            >
              <img
                src={Img4}
                className="img-fluid rounded-3"
                alt="about"
                style={imageStyle}
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 mb-3"
              style={galleryItemStyle}
            >
              <img
                src={Img5}
                className="img-fluid rounded-3"
                alt="about"
                style={imageStyle}
              />
            </div>
          </div>
          <div className="text-center text-white mb-4">
            <Link to="/gallery">
              <button
                className="btn btn p-2 text-white"
                type="button"
                style={viewButtonStyle}
              >
                View Gallery
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section id="ParentsCorner">
        <div className="container mt-4 mb-4">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-12 text-center mt-4">
              <h4 className="text-danger serh4">Parent's Speak</h4>
            </div>
          </div>
          <div className="row">
            <div className="p-3 mb-2 bg-white rounded mt-4">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                  <div className="card">
                    <img className="card-img-top" src={fam1} alt="Card 1" />
                    <div className="card-body">
                      <p className="card-text">
                        <h3
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          Imaginative & Safe Learning Haven
                        </h3>
                        Our Little Elly provided a secure and imaginative haven
                        for our child to explore and grow.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                  <div className="card">
                    <img className="card-img-top" src={fam2} alt="Card 2" />
                    <div className="card-body">
                      <p className="card-text">
                        <h3
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          Remarkable Growth & Confidence
                        </h3>
                        Our child's growth at Our Little Elly has been truly
                        remarkable, both academically and socially.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                  <div className="card">
                    <img className="card-img-top" src={fam3} alt="Card 3" />
                    <div className="card-body">
                      <p className="card-text">
                        <h3
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            color: "#dc3545",
                          }}
                        >
                          Foundation for Lifelong Learning
                        </h3>
                        The educational foundation laid at Our Little Elly has
                        been exceptional, setting a strong base for continuous
                        learning.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="achievements">
        <div className="container-fluid bg-light">
          <div className="row bg-light-img m-lg-5 py-5 mt-0">
            <div className="col text-center">
              <h4
                className="text-secondary mb-2"
                style={{ fontFamily: "Roboto, sans-serif" }}
              >
                Our Achievements
              </h4>
              <h2
                className="title-1"
                style={{ fontFamily: "Roboto, sans-serif", color: "#dc3545" }}
              >
                Winning Awards. Winning Hearts.
              </h2>
              <p
                className="sub-title"
                style={{ fontFamily: "Roboto, sans-serif" }}
              ></p>
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img src={ach1} alt="Nurture" className="card-img-top" />
                    </div>
                  </div>
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img
                        src={ach2}
                        alt="Curiosity"
                        className="card-img-top"
                      />
                    </div>
                  </div>
                  <div className="col-sm">
                    <div
                      className="card m-auto mt-3"
                      style={{ width: "18rem" }}
                    >
                      <img src={ach3} alt="Wonder" className="card-img-top" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section style={bannerStyle}>
        <div style={contentStyle}></div>
        <img src={home2} style={imgStyle} alt="" />
      </section>
      <section>
        <div className="container-fluid bg-light text-center ">
          <h2
            className="title-1 mb-2"
            style={{ fontFamily: "Roboto, sans-serif", color: "#dc3545" }}
          >
            Our Childrens videos
          </h2>
          <Videocarousel />
        </div>
      </section>
      <section>
        <div className="App mt-2 mb-4">
          <header className="App-header">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3 text-center mt-4">
              <h4 className="text-success serh4">Happy Parents</h4>
            </div>
            <Testimonials />
          </header>
        </div>
      </section>
    </div>
  );
};

export default Mainpage;
