import React from "react";
import Img from "../images/galleryhome1.jpg";
import Img1 from "../images/little1.jpg";
import Img2 from "../images/little2.jpg";
import Img3 from "../images/little3.jpg";
import Img4 from "../images/little4.jpg";
import Img5 from "../images/little5.jpg";
import Img6 from "../images/little6.jpg";
import Img7 from "../images/little7.jpg";
import Img8 from "../images/little8.jpg";
import Img10 from "../images/little10.jpg";
import Img11 from "../images/little11.jpg";
import Img12 from "../images/little12.jpg";
import Img14 from "../images/little14.jpg";
import Img15 from "../images/little15.jpg";
import Img16 from "../images/little16.jpg";
import Img17 from "../images/little17.jpg";
import Img19 from "../images/little19.jpg";
import Img23 from "../images/little23.jpg";
import Img24 from "../images/little24.jpg";
import Img25 from "../images/little25.jpg";
import Img26 from "../images/little26.jpg";
import Img27 from "../images/little27.jpeg";
import Img28 from "../images/little28.jpeg";
import Img29 from "../images/little29.jpeg";
import Img30 from "../images/little30.jpeg";
import Img31 from "../images/little31.jpeg";

const Gallery = () => {
  const bannerStyle = {
    display: "flex",
    position: "relative",
  };
  const imageStyle = {
    width: "100%",
    height: "auto",
  };

  return (
    <>
    <section style={bannerStyle}>
        
        <img src={Img} style={imageStyle} alt="" />
      </section>
      <section>
        <div className="container-fluid bg-light">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-12 text-center mt-4">
              <h4 className="text-danger serh4">Our Children Gallery</h4>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="p-3 mb-2 bg-white rounded mt-4 d-flex justify-content-between flex-wrap">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img1}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img2}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img3}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img4}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img5}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img6}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img7}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img8}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img10}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img11}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img12}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img14}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img15}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img16}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img17}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img19}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img23}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img24}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img25}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img26}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img27}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img28}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img29}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img30}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <img
                  src={Img31}
                  className="img-fluid rounded-3"
                  alt="about"
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Gallery;
