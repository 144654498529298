import React, { useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Img from "../images/logo.png";
import {
  BsTelephoneFill,
  BsFillEnvelopeAtFill,
  BsGeoAltFill,
  BsFacebook,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
import "./Navbar.css";
import Marquee from "react-fast-marquee";

function Header() {
  return (
    <>
      <div
        className=" text-center"
        style={{
          backgroundColor: "#dc3545",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Navbar.Brand href="#">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-5 col-sm-12 mt-2 order-sm-1 order-2">
                  <Marquee className="text-white">
                    <BsGeoAltFill className="icon" /> Location: 203, Saptagiri ,
                    4th, Main, 15th Cross Rd, Ananth Nagar, Phase 1,
                    Kammasandra, Electronic City, Bengaluru, Karnataka 560100
                  </Marquee>
                </div>

                <div className="col-lg-3 col-sm-12 col-md-12 mt-2 order-sm-2 order-1 text-white">
                  <BsTelephoneFill className="icon" />
                  <a href="tel:+919663727426" className="brand-text">Phone: +91 9663727426</a>
                </div>
                <div className="col-lg-4 col-sm-12 mt-2 order-sm-3 order-3 text-white">
                  <BsFillEnvelopeAtFill className="icon" />
                  <a href="mailto:anathnagar@littleelly.com" className="brand-text">anathnagar@littleelly.com</a>
                </div>
              </div>
            </div>
          </div>
        </Navbar.Brand>
      </div>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{
          backgroundColor: "rgb(236, 223, 223)",
          fontFamily: "Roboto, sans-serif",
        }}
        variant="white"
        sticky="top"
        className="text-white ps-4 custom-dropdown"
      >
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              src={Img}
              className="logo-img"
              alt="Logo"
              width="100%"
              height="60px"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarCollapse"
            className="text-black text-end"
          />
          <Navbar.Collapse
            id="navbarCollapse"
            className="justify-content-center"
          >
            <Nav className="ms-auto mb-2 mb-md-0 text-center w-100">
              <Nav.Link href="./" className="ps-4 pe-4">
                Home
              </Nav.Link>
              <Nav.Link href="#about" className="ps-4 pe-4">
                About Us
              </Nav.Link>
              <Nav.Link href="#achievements" className="ps-4 pe-4">
                Achivements
              </Nav.Link>

              <Nav.Link href="#gallery" className="ps-4 pe-4">
                Gallery
              </Nav.Link>
              <Nav.Link href="#events" className="ps-4 pe-4">
                Events
              </Nav.Link>
              <Nav.Link href="#ParentsCorner" className="ps-4 pe-4">
                Parent's Corner
              </Nav.Link>
              {/* <Nav.Link href="" className="ps-4 pe-4">
                Contact Us
              </Nav.Link> */}

              <div className="d-flex flex-row justify-content-center align-items-center">
                <Nav.Link
                  href="https://www.facebook.com/LittleEllyOnlinePreschoolAnanthNagar"
                  target="_blank"
                >
                  <BsFacebook className="icon text-primary" />
                </Nav.Link>
                <Nav.Link
                  href="https://www.instagram.com/leananthnagar/"
                  target="_blank"
                >
                  <BsInstagram className="icon text-danger" />
                </Nav.Link>
                <Nav.Link
                  href="https://www.youtube.com/@PreschoolLittleElly"
                  target="_blank"
                >
                  <BsYoutube className="icon text-danger" />
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
